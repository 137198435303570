<template>
<ModalMain
  no-padding
  show-close
  show-close-mobile
  class="p-2"
  @close="close"
>
  <div class="flex justify-center w-full relative z-0 rounded-md overflow-hidden">
    <transition name="fade-fast">
      <div v-if="showPlayButton" class="absolute inset-0 flex justify-center items-center text-white bg-black/50 backdrop-blur-sm" @click.stop="playVideo">
        <SvgIcon
          icon="play"
          width="64"
          height="64"
        />
      </div>
    </transition>
    <video
      ref="introVideoEl"
      :poster="placeholder"
      webkit-playsinline
      playsinline
      width="480"
      height="480"
      @click.stop="toggleVideoPlay"
    >
      <source :src="introVideo" type="video/mp4">
    </video>
  </div>
</ModalMain>
</template>

<script setup>
import introVideo from '~/assets/video/introduction.mp4';
import placeholder from '~/assets/img/bgs/metawin-background.png';

const introVideoEl = ref();
const showPlayButton = ref(true);
const isAutoplay = ref(false);

const uiStore = useUiStore();
const { showIntroductionModal, } = storeToRefs(uiStore);

function close() {
  showIntroductionModal.value = false;
}

function toggleVideoPlay() {
  if (introVideoEl.value.paused) {
    playVideo();
  } else {
    pauseVideo();
  }
}

function playVideo() {
  if (introVideoEl.value) {
    introVideoEl.value.play().then(() => {
      showPlayButton.value = false;
    }).catch(() => {
      showPlayButton.value = true;
    });
  }
}

function pauseVideo() {
  if (introVideoEl.value) {
    introVideoEl.value.pause();
    showPlayButton.value = true;
  }
}

onMounted(() => {
  setTimeout(() => {
    if (isAutoplay.value) {
      playVideo();
    }
    introVideoEl.value?.addEventListener('ended', close);
  }, 350);
});
</script>
